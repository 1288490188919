import React from 'react';
import styled from 'styled-components/native';

import { BACKGROUND_COLOR_TITLE } from 'constants/theme';
import NText from 'components/NText';

const Title = styled.View.attrs(({ children }) => ({
	children: (
		<NText
			textTransform="uppercase"
			fontColor="third"
			fontWeight="bold"
			fontSize="l">
			{children}
		</NText>
	),
}))`
	align-self: center;
	padding-vertical: 4;
	padding-horizontal: 25;
	background-color: ${BACKGROUND_COLOR_TITLE};
`;

Title.defaultProps = {
	backgroundColor: 'primary',
};

export default Title;
