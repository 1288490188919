import React from 'react';
import { View } from 'react-native';
import styled from 'styled-components/native';
import { connect } from 'react-redux';
import { saveQuizResult } from '../../redux/actions';

import Container from 'components/Container';
import QuizButton from 'components/QuizButton';
import NText from 'components/NText';

const Question = styled.View.attrs(({ questionInfo, question }) => ({
	children: (
		<>
			{questionInfo && (
				<NText style={{ marginBottom: 20 }}>{questionInfo} </NText>
			)}
			<NText fontWeight="bold">{question}</NText>
		</>
	),
}))`
	flex: 3;
	padding: 20px;
	background-color: white;
	box-shadow: 0px 3px 3px #d6d6d6;
`;

const Choices = styled(Container)`
	flex: 4;
	align-items: center;
	justify-content: center;
`;

const Quiz1 = ({
	navigation,
	screenProps: { questionInfo, question, choices, response },
	saveQuizResult,
}) => {
	const goToNextScreen = index => {
		const success = response.index.includes(index);
		saveQuizResult(success);
		setTimeout(
			() =>
				navigation.navigate('Quiz2', {
					success: success,
				}),
			1000,
		);
	};

	return (
		<View style={{ flex: 1, backgroundColor: '#F5F5F5' }}>
			<Question questionInfo={questionInfo} question={question} />

			<Choices>
				{choices &&
					choices.map((choice, index) => (
						<QuizButton
							key={index}
							activeOpacity={0.7}
							goToNextScreen={() => goToNextScreen(index)}>
							{choice}
						</QuizButton>
					))}
			</Choices>
		</View>
	);
};

const mapDispatchToProps = (dispatch, ownProps) => {
	const { id } = ownProps.screenProps;
	return {
		saveQuizResult: success => dispatch(saveQuizResult(id, success)),
	};
};

export default connect(null, mapDispatchToProps)(Quiz1);
