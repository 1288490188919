import React from 'react';
import { View } from 'react-native';
import styled from 'styled-components/native';

import { vh } from 'constants/units';
import { BACKGROUND_COLOR_BUTTON } from 'constants/theme';
import NText from 'components/NText';
import SVG from 'components/SVG';

const NButton = styled.TouchableOpacity.attrs(({ children, iconName }) => ({
	children: (
		<>
			<NText
				fontColor="third"
				fontWeight="bold"
				textTransform="uppercase"
				textAlign="center">
				{children}
			</NText>

			{iconName && (
				<SVG
					name={iconName}
					height="50%"
					style={{ paddingLeft: '5%' }}
				/>
			)}
		</>
	),
}))`
	background-color: ${BACKGROUND_COLOR_BUTTON};
	width: ${({ width }) => width};
	height: ${5 * vh};
	border-radius: 6px;
	box-shadow: 0px 0px 6px #00000029;
	flex-direction: row;
	justify-content: center;
	align-items: center;
`;

export default NButton;
