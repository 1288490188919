import React, { useState } from 'react';
import styled from 'styled-components/native';

import { vh } from 'constants/units';
import NText from 'components/NText';

import {
	BACKGROUND_COLOR_QUIZ_BUTTON,
	COLOR_QUIZ_BUTTON,
} from 'constants/theme';

const TextButton = styled(NText)`
	color: ${COLOR_QUIZ_BUTTON};
`;

const ButtonWrapper = styled.TouchableOpacity`
	width: 100%;
	padding-vertical: ${3 * vh}px;
	margin-bottom: ${1 * vh}px;
	background-color: ${props => BACKGROUND_COLOR_QUIZ_BUTTON(props)};
	box-shadow: 0px 0px 5px #d6d6d6;
	border-radius: 6px;
`;

const QuizButton = ({ kind, children, goToNextScreen }) => {
	const [isClicked, setClicked] = useState(false);

	const setKind = () =>
		goToNextScreen ? (isClicked ? 'clicked' : 'default') : kind;

	return (
		<ButtonWrapper
			kind={setKind()}
			onPress={() => {
				setClicked(true);
				goToNextScreen();
			}}>
			<TextButton style={{ textAlign: 'center' }} kind={setKind()}>
				{children}
			</TextButton>
		</ButtonWrapper>
	);
};

TextButton.defaultProps = {
	kind: 'default',
};

QuizButton.defaultProps = {
	kind: 'default',
};

export default QuizButton;
