import React from 'react';
import { View } from 'react-native';
import styled from 'styled-components/native';

import Container from 'components/Container';
import NText from 'components/NText';
import NButton from 'components/NButton';
import Title from 'components/Title';
import SVG from 'components/SVG';

const PartnersIcons = styled.View`
	flex-direction: row;
	justify-content: space-between;
	padding-horizontal: 4%;
	margin-top: 4%;
	position: absolute;
	width: 100%;
	top: 0;
`;

const Card = styled.View.attrs(({ imageName, text }) => ({
	children: (
		<>
			<SVG name={imageName} width="90%" style={{ padding: '15px 0' }} />

			<NText
				textAlign="center"
				fontColor="third"
				fontWeight="bold"
				style={{
					backgroundColor: '#D0263D',
					paddingVertical: 10,
					paddingHorizontal: 15,
				}}>
				{text}
			</NText>
		</>
	),
}))`
	background-color: white;
	width: 100%;
	max-height: 220px;
	border-radius: 6px;
	overflow: hidden;
	align-items: center;
`;

const Intro = ({ navigation }) => {
	return (
		<View
			style={{
				flex: 1,
				backgroundColor: '#f5f5f5',
				flexDirection: 'column',
			}}>
			<PartnersIcons>
				<SVG name="cityLogo" height={38} />
				<SVG name="wemapLogo" height={38} />
			</PartnersIcons>

			<Title style={{ marginTop: 40 }}>Paris à 360°</Title>

			<Container
				style={{
					flex: 1,
					justifyContent: 'space-around',
					alignItems: 'center',
				}}>
				<View
					style={{
						width: '60%',
						maxHeight: 150,
					}}>
					<SVG name="nexityBaseline" width={'100%'} />
				</View>

				<Card
					imageName="phone"
					text="Testez vos connaissances sur les monuments parisiens et découvrez les réalisations emblématiques de Nexity en réalité augmentée."
				/>

				<NButton
					backgroundColor="primary"
					width="80%"
					onPress={() => {
						navigation.navigate('Map');
					}}>
					Démarrer la visite
				</NButton>
			</Container>
		</View>
	);
};

export default Intro;
