const fetchData = () => {
	const URL = 'https://api.getwemap.com/v3.0/pinpoints/search?format=json&list=65030';
	return fetch(URL, { method: 'GET' }).then(response =>
		Promise.all([response, response.json()]),
	);
};

export {
    fetchData
}
