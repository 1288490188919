import React from 'react';
import { createAppContainer } from 'react-navigation';
import { createStackNavigator } from 'react-navigation-stack';
import { connect } from 'react-redux';
import styled from 'styled-components/native';

import QuizCover from 'components/QuizCover';
import Quiz1 from './Quiz1';
import Quiz2 from './Quiz2';

const MainScreenStackNavigator = createStackNavigator(
	{
		Quiz1,
		Quiz2,
	},
	{
		initialRouteName: 'Quiz1',
		headerMode: 'none',
		navigationOptions: { header: { visible: false } },
		defaultNavigationOptions: {
			cardStyle: { backgroundColor: '#fff' },
		},
	},
);

const QuizMain = createAppContainer(MainScreenStackNavigator);

const Quiz = styled.View.attrs(
	({
		building: {
			id,
			name,
			imageSource,
			quiz: { questionInfo, question, choices, response },
		},
		navigation,
	}) => {
		return {
			children: (
				<div
					style={{
						height: '100%',
						display: 'flex',
						flexDirection: 'column',
					}}>
					<QuizCover
						imageSource={imageSource}
						title={name}
						navigation={navigation}
					/>
					<QuizMain
						style={{ flex: 1 }}
						screenProps={{
							id,
							questionInfo,
							question,
							choices,
							response,
							navigation,
						}}
					/>
				</div>
			),
		};
	},
)`
	flex: 1;
`;

const mapStateToProps = (state, { navigation }) => {
	const id = navigation.getParam('id');

	const buildings = state.buildings;
	const building = buildings.filter(building => building.id === id)[0];

	const name = building && building.name;
	const imageSource = building && building.media_url;

	const quiz = building && building.external_data;
	const questionInfo = quiz && quiz.question_info;
	const question = quiz && quiz.question;
	const choices = quiz && quiz.choices;
	const response = quiz && quiz.response;

	return {
		building: {
			id,
			name,
			imageSource,
			quiz: { questionInfo, question, choices, response },
		},
	};
};

export default connect(mapStateToProps)(Quiz);
