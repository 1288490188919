import { createGlobalStyle } from 'styled-components';
import * as fonts from '../assets/fonts';

const GlobalStyle = createGlobalStyle`
@font-face {
    font-family: 'Nexity';
    src: url(${fonts.NexityRegularTTF}) format('truetype');
    font-weight: normal;
}
@font-face {
    font-family: 'Nexity';
    src: url(${fonts.NexityBoldTTF}) format('truetype');
    font-weight: bold;
}
`;

export default GlobalStyle;
