import { combineReducers } from 'redux';
import {
	FETCH_SUCCESS,
	SAVE_QUIZ_RESULT,
	INIT_ACHIEVED_MISSIONS,
} from '../actions';

// store id of each achieve building
export const achievedBuildings = (state = new Map(), action) => {
    switch (action.type) {
		case INIT_ACHIEVED_MISSIONS:
			return action.achievedMissionsMap;
        case SAVE_QUIZ_RESULT:
            return state.set(action.id, action.success);
        default:
            return state;
    }
};

// store mirrored data from wemap api to reflect state of the app
export const buildings = (state = [], action) => {
    switch (action.type) {
        case FETCH_SUCCESS:
            return action.value;
        case SAVE_QUIZ_RESULT:
			let updatedBuilding = state.find(building => building.id === action.id);
			updatedBuilding.success = action.success;
            return state;
        default:
            return state;
    }
};

export default combineReducers({
    achievedBuildings,
    buildings,
});
