import React from 'react';
import styled from 'styled-components/native';
import { connect } from 'react-redux';
import { Text } from 'react-native';

import Container from 'components/Container';
import Title from 'components/Title';
import NText from 'components/NText';
import SVG from 'components/SVG';
import NButton from 'components/NButton';

const EndContainer = styled(Container)`
	align-items: center;
	justify-content: space-around;
`;

const EndTitle = styled(Title)`
	margin-top: 10%;
`;

const ScoreContainer = styled.View`
	align-items: center;
`;

const SNContainer = styled.View`
	width: 100%;
`;

const SNText = styled(NText)`
	margin-bottom: 10%;
`;

const SNLinks = styled.View`
	width: 100%;
	flex-direction: row;
	justify-content: space-around;
`;

const SNLink = styled.TouchableOpacity.attrs(({ name }) => ({
	children: <SVG name={name} height="30px" />,
	accessibilityRole: 'link',
	target: '_blank',
}))`
	flex: 1;
`;

const End = ({ quizSucceededCount, quizTotalCount }) => {
	return (
		<EndContainer>
			<EndTitle>Bravo</EndTitle>

			<NText textAlign="center">Vous avez un score de :</NText>

			<ScoreContainer>
				<NText textAlign="center">
					<NText fontWeight="bold">{quizSucceededCount}</NText>/
					{quizTotalCount}
				</NText>

				<SVG name="podium" width="40%" />
			</ScoreContainer>

			<NButton width="100%" backgroundColor="primary">
				<Text
					accessibilityRole="link"
					href="https://www.nexity.fr/lacite/la-cite"
					target="_blank">
					En savoir plus sur Nexity
				</Text>
			</NButton>

			<SNContainer>
				<SNText color="secondary" textAlign="center">
					Suivez-nous sur les réseaux sociaux !
				</SNText>

				<SNLinks>
					<SNLink
						name="facebook"
						href="https://www.facebook.com/laTribuNexity/"
					/>
					<SNLink
						name="twitter"
						href="https://twitter.com/Nexity_talents"
					/>
					<SNLink
						name="instagram"
						href="https://www.instagram.com/nexityimmobilier/?hl=fr"
					/>
					<SNLink
						name="linkedin"
						href="https://www.linkedin.com/company/nexity/"
					/>
				</SNLinks>
			</SNContainer>

			<SVG name="nexityBaseline" height="18%" />
		</EndContainer>
	);
};

const mapStateToProps = state => {
	const buildings = state.buildings && state.buildings;
	const quizSucceededCount =
		buildings && buildings.reduce((a, e) => (e.success ? ++a : a), 0);

	return {
		quizTotalCount: buildings.length,
		quizSucceededCount,
	};
};

export default connect(mapStateToProps)(End);
