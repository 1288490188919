import React from 'react';

import { ReactComponent as cross } from 'assets/icons/cross.svg';
import { ReactComponent as check } from 'assets/icons/check.svg';
import { ReactComponent as trophy } from 'assets/icons/trophy.svg';
import { ReactComponent as wemapLogo } from 'assets/icons/wemap-logo.svg';
import { ReactComponent as cityLogo } from 'assets/icons/city-logo.svg';
import { ReactComponent as nexityBaseline } from 'assets/icons/nexity-baseline.svg';
import { ReactComponent as phone } from 'assets/icons/phone.svg';
import { ReactComponent as like } from 'assets/icons/like.svg';
import { ReactComponent as prize } from 'assets/icons/prize.svg';
import { ReactComponent as podium } from 'assets/icons/podium.svg';
import { ReactComponent as facebook } from 'assets/icons/facebook.svg';
import { ReactComponent as instagram } from 'assets/icons/instagram.svg';
import { ReactComponent as twitter } from 'assets/icons/twitter.svg';
import { ReactComponent as linkedin } from 'assets/icons/linkedin.svg';
import { ReactComponent as nexity } from 'assets/icons/nexity.svg';

const icons = {
	cross,
	check,
	trophy,
	wemapLogo,
	cityLogo,
	nexityBaseline,
	phone,
	like,
	prize,
	podium,
	facebook,
	instagram,
	twitter,
	linkedin,
	nexity,
};

const SVG = ({ name, width, height, ...props }) => {
	const Icon = icons[name];
	return (
		<Icon
			width={!width && !height ? 22 : width}
			height={height}
			{...props}
		/>
	);
};

export default SVG;
