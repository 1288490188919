import React from 'react';
import ReactDOM from 'react-dom';
import { Provider as ReduxProvider } from 'react-redux';
import { ThemeProvider } from 'styled-components/native';

import App from './App';
import store from './redux';
import GlobalStyle from './styles/GlobalStyle';
// import * as serviceWorker from './serviceWorker';

ReactDOM.render(
	<ReduxProvider store={store}>
		<ThemeProvider theme={{ mode: 'default' }}>
			<GlobalStyle />
			<App />
		</ThemeProvider>
	</ReduxProvider>,
	document.getElementById('root'),
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
// serviceWorker.unregister();
