import React from 'react';
import { connect } from 'react-redux';
import AppNavigator from 'screens';

import { fetchDataWithRedux, initAchievesMissions } from './redux/actions';
import { getAchievedMissionsMap } from './services/storage';

class App extends React.Component {

	componentDidMount = () => {
		const { fetchDataWithRedux, initAchievesMissions } = this.props;

		const achievedMissionsMap = getAchievedMissionsMap();

		if (achievedMissionsMap) {
			initAchievesMissions(achievedMissionsMap);
		}

		fetchDataWithRedux();
	};

	render() {
		return (
			<AppNavigator />
		);
	}
}

const mapStateToProps = state => {
	return {
		data: state,
	};
};

const mapDispatchToProps = dispatch => {
	return {
		fetchDataWithRedux: () => dispatch(fetchDataWithRedux()),
		initAchievesMissions: missionsMap => dispatch(initAchievesMissions(missionsMap)),
	};
};

export default connect(mapStateToProps, mapDispatchToProps)(App);
