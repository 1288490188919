import React from 'react';
import { View, ScrollView, FlatList } from 'react-native';
import { connect } from 'react-redux';
import styled from 'styled-components/native';

import Container from 'components/Container';
import Title from 'components/Title';
import NText from 'components/NText';
import SVG from 'components/SVG';
import CloseButton from 'components/CloseButton';
import NButton from 'components/NButton';

const ScoreTitle = styled(Title)`
	margin-vertical: 10%;
`;

const ScoreListItemImage = styled.Image`
	height: 40;
	width: 40;
	border-radius: 50;
	margin-right: 5%;
`;

const ScoreListItemInfoWrapper = styled.TouchableOpacity`
	flex: 1;
	flex-direction: row;
	align-items: center;
`;

const ScoreListItem = styled.View.attrs(
	({ name, imageSource, success, onPress }) => ({
		children: (
			<>
				<ScoreListItemInfoWrapper onPress={onPress}>
					<ScoreListItemImage source={imageSource} />
					<NText fontWeight="bold">{name}</NText>
				</ScoreListItemInfoWrapper>

				{success !== undefined &&
					(success ? (
						<SVG name="check" />
					) : (
						<SVG name="cross" color="#bababa" />
					))}
			</>
		),
	}),
)`
	flex-direction: row;
	justify-content: space-between;
	align-items: center;
	background-color: #f5f5f5;
	padding-horizontal: 5%;
	margin-bottom: 5%;
	border-radius: 6px;
	box-shadow: ${({ success }) =>
		success !== undefined ? '2px 2px 6px #00000029' : 'none'};
	opacity: ${({ success }) => (success !== undefined ? 1 : 0.7)};
	margin-horizontal: 6px;
	height: 60px;
`;

const Score = ({ buildings, navigation }) => {
	return (
		<ScrollView>
			<CloseButton
				color={'#333333'}
				style={{ position: 'absolute', top: 0, right: 0 }}
				onPress={() => navigation.popToTop()}
			/>
			<ScoreTitle>Score</ScoreTitle>

			<FlatList
				data={buildings.sort(
					(a, b) =>
						a.success === undefined
							? 1
							: b.success === undefined
							? -1
							: 0, // a.id - b.id
				)}
				renderItem={({ item: { id, name, imageSource, success } }) => (
					<ScoreListItem
						name={name}
						imageSource={imageSource}
						success={success}
						onPress={() =>
							navigation.navigate('Map', { pinpointId: id })
						}
					/>
				)}
				keyExtractor={({ id }) => id.toString()}
				style={{ marginHorizontal: '5%', padding: '2%' }}
			/>

			<NButton
				backgroundColor="primary"
				width="50%"
				style={{ marginHorizontal: 'auto', marginVertical: 30 }}
				onPress={() => navigation.navigate('End')}>
				Terminer
			</NButton>
		</ScrollView>
	);
};

const mapStateToProps = state => {
	return {
		buildings: state.buildings.map(({ id, name, media_url, success }) => ({
			id,
			name,
			imageSource: media_url,
			success,
		})),
	};
};

export default connect(mapStateToProps)(Score);
