import React from 'react';
import { loadScript } from 'constants/utils';
import styled from 'styled-components/native';
import { connect } from 'react-redux';
import { NavigationEvents } from 'react-navigation';

import SVG from 'components/SVG';
import Switcher from 'components/Switcher';

const QUIZPP_EMMID = 12087;
const NEXITY_EMMID = 12517;

const ScoreButton = styled.TouchableOpacity`
	position: absolute;
	background-color: #ff8e70;
	z-index: 2;
	width: 40px;
	height: 40px;
	right: 10px;
	bottom: ${props => (props.isGalleryButtonDisplayed ? 105 : -200)}px;
	border-radius: 50px;
	display: flex;
	align-items: center;
	justify-content: center;
	margin-bottom: 10px;
	box-shadow: rgba(0, 0, 0, 0.25) 0px 2px 5px;
`;

const PPSwitcher = styled(Switcher)`
	position: absolute;
	bottom: ${props => (props.isGalleryButtonDisplayed ? 65 : -200)}px;
	left: 10px;
	z-index: 2;
`;

const wemapScript = loadScript('https://livemap.getwemap.com/js/sdk.min.js');

class Map extends React.Component {
	state = {
		isReady: false,
		isGalleryButtonDisplayed: true,
		isLivebarOpen: false,
	};

	hideGalleryButton = withDelay => {
		setTimeout(
			() => {
				this.setState({
					isGalleryButtonDisplayed: false,
				});
			}, withDelay ? 400 : 0);
	};

	showGalleryButton = () => {
		this.setState({
			isGalleryButtonDisplayed: true,
		});
	};

	toggleLivebarState = () => {
		this.setState(({ isLivebarOpen }) => ({
			isLivebarOpen: !isLivebarOpen,
		}), () => {
			this.state.isLivebarOpen ?
				this.hideGalleryButton() :
				this.showGalleryButton() ;
		});
	};

	_switchPP = enabled => {
		window.livemap.setEMMID(enabled ? NEXITY_EMMID : QUIZPP_EMMID);
	};

	openPinpoint = pinpointId => {
		window.livemap.openPinpoint(pinpointId);
	}

	// use MutationObserver to monitor livebar open & close. Trigger display / hide gallery button.
	// => rerun on pinpointClose
	observeLivebar = () => {
		const livebar = document.getElementsByClassName(
			'wemap-livebar-view-innerbox',
		)[0];
		const observer = new MutationObserver(() => {
			this.toggleLivebarState();
		});
		if (livebar) {
			observer.observe(livebar, { attributes: true, childList: true });
			if (livebar.childElementCount > 1) {
				// livebar is open
				this.setState({
					isLivebarOpen: true,
				});
				this.hideGalleryButton();
			}
		}
	};

	onOpenPinpoint = ({ pinpoint }) => {
		const { achievedBuildings } = this.props;

		this.hideGalleryButton(true);

		const elt = document.getElementsByClassName('wemap-template-button')[0];

		if (achievedBuildings.has(pinpoint.id)) {
			elt.textContent = 'DÉJÀ JOUÉ';
			elt.style.setProperty('background-color', '#BABABA');
			return;
		}

		elt &&
			elt.addEventListener('click', e => {
				this.props.navigation.navigate('Quiz', {
					id: pinpoint.id,
				});
				window.livemap.closePinpoint();
				setTimeout(() => {
					window.livemap.setCenter({
						longitude: pinpoint.longitude,
						latitude: pinpoint.latitude,
					});
				}, 2000);
			});
	};

	async componentDidMount() {
		this.props.navigation.navigate('Intro');

		const wemap = await wemapScript
			.then(() => window.wemap)
			.catch(console.log);

		const options = {
			emmid: QUIZPP_EMMID,
			initialbearing: 0,
			pitch: 0,
			method: 'dom',
			arviewenabled: true,
			deeplinkingenabled: false,
			enablepetals: false,
			arviewsearchradius: 50000,
		};
		window.livemap = wemap.v1.createLivemap(
			this.mapContainer,
			options,
			false,
		);
		window.livemap.waitForReady().then(() => {
			this.setState({ isReady: true });

			// attach listener to description button
			window.livemap.addEventListener(
				'pinpointOpen',
				this.onOpenPinpoint,
			);

			window.livemap.addEventListener('pinpointClose', () => {
				this.showGalleryButton();
				this.setState({
					isLivebarOpen: false,
				});
				this.observeLivebar();
			});

			this.observeLivebar();
		});
	}

	render() {
		const { isReady, isGalleryButtonDisplayed } = this.state;
		const { navigation } = this.props;
		return (
			<>
			<NavigationEvents
				onDidFocus={({ state }) => {
					if (state && state.params && state.params.pinpointId) {
						this.openPinpoint(state.params.pinpointId);
					}
				}}
			/>
			<div
				style={{ height: '100%', position: 'relative' }}
				ref={node => {
					this.mapContainer = node;
				}}>
				{isReady && (
					<>
						<ScoreButton
							onPress={() => {
								navigation.navigate('Score');
							}}
							isGalleryButtonDisplayed={isGalleryButtonDisplayed}
							children={<SVG name="trophy" />}
						/>
						<PPSwitcher
							handleSwitch={this._switchPP}
							isGalleryButtonDisplayed={isGalleryButtonDisplayed}
						/>
					</>
				)}
			</div>
			</>
		);
	}
}

const mapStateToProps = ({ achievedBuildings, buildings }) => {
	return {
		achievedBuildings,
		buildings: buildings.map(({ id, name, media_url, success }) => ({
			id,
			name,
			imageSource: media_url,
			success,
		})),
	};
};

export default connect(mapStateToProps)(Map);
