import React from 'react';
import styled from 'styled-components/native';

import Container from 'components/Container';
import QuizButton from 'components/QuizButton';
import NButton from 'components/NButton';
import NText from 'components/NText';
import SVG from 'components/SVG';

const Quiz2Container = styled(Container)`
	align-items: center;
	justify-content: space-around;
`;

const ResultMessage = styled.View`
	align-items: center;
`;

const ButtonsWrapper = styled.View`
	width: 100%;
	flex-direction: row;
	justify-content: space-between;
`;

const Quiz2 = ({
	navigation,
	screenProps: { choices, response, navigation: appNavigation },
}) => {
	const success = navigation.getParam('success');
	return (
		<Quiz2Container>
			{success ? (
				<>
					<SVG name="prize" width={50} />
					<ResultMessage>
						<NText
							fontWeight="bold"
							textTransform="uppercase"
							style={{ marginBottom: '5%' }}>
							Bravo !
						</NText>
						<NText fontWeight="bold">
							Vous avez trouvé la bonne réponse !
						</NText>
					</ResultMessage>
				</>
			) : (
				<>
					<SVG name="like" width={50} />
					<ResultMessage>
						<NText
							fontWeight="bold"
							textTransform="uppercase"
							style={{ marginBottom: '5%' }}>
							Bien essayé !
						</NText>
						<NText fontWeight="bold">
							La bonne réponse était :
						</NText>
					</ResultMessage>

					<QuizButton activeOpacity={1} kind="clicked">
						{choices[response.index]}
					</QuizButton>
				</>
			)}
			<NText textAlign="center" fontColor="secondary">
				{response.text}
			</NText>

			<ButtonsWrapper>
				<NButton
					width="45%"
					backgroundColor="secondary"
					iconName="trophy"
					onPress={() => {
						appNavigation.navigate('Score');
					}}>
					Score
				</NButton>
				<NButton
					width="45%"
					backgroundColor="primary"
					onPress={() => {
						appNavigation.navigate('Map');
					}}>
					Continuer
				</NButton>
			</ButtonsWrapper>
		</Quiz2Container>
	);
};

export default Quiz2;
