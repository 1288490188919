import React, { Component } from 'react';
import { Animated, TouchableWithoutFeedback } from 'react-native';
import styled from 'styled-components/native';

import SVG from 'components/SVG';

const CONTAINER_WIDTH = 60;
const CIRCLE_DIAMETER = 0.54 * CONTAINER_WIDTH;
const START_POSITION = 0;
const END_POSITION = CONTAINER_WIDTH - CIRCLE_DIAMETER;
const BORDER_WIDTH = 1;

const Container = styled.View`
	width: ${CONTAINER_WIDTH}px;
	height: ${CIRCLE_DIAMETER}px;
	background-color: #ffffff;
	border: ${({ enabled }) => (enabled ? '#d22238' : '#bababa')} solid
		${BORDER_WIDTH}px;
	border-radius: 50px;
`;

const Circle = styled.View`
	height: ${CIRCLE_DIAMETER - 2 * BORDER_WIDTH}px;
	width: ${CIRCLE_DIAMETER - 2 * BORDER_WIDTH}px;
	background-color: ${({ enabled }) => (enabled ? '#d22238' : '#bababa')};
	border-radius: 50px;
	position: relative;
	align-items: center;
	justify-content: center;
`;

class Switcher extends Component {
	state = {
		enabled: false,
	};

	position = new Animated.Value(START_POSITION);

	_switch = () => {
		const { handleSwitch } = this.props;
		Animated.timing(this.position, {
			toValue: this.state.enabled ? START_POSITION : END_POSITION,
			duration: 300,
		}).start(() => {
			this.setState(
				({ enabled }) => ({ enabled: !enabled }),
				() => handleSwitch(this.state.enabled),
			);
		});
	};

	render() {
		const { style } = this.props;
		const { enabled } = this.state;
		return (
			<Container enabled={enabled} style={style}>
				<TouchableWithoutFeedback onPress={this._switch}>
					<Animated.View style={{ left: this.position }}>
						<Circle enabled={enabled}>
							<SVG name="nexity" width="80%" />
						</Circle>
					</Animated.View>
				</TouchableWithoutFeedback>
			</Container>
		);
	}
}

export default Switcher;
